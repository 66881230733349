import { Typography } from '@mui/material';
import { Modal } from '@automata/ui';
import { AsyncStatus } from 'components/AsyncStatus';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { TrackedButton } from '@automata/ui';

const styles = {
  cancelButton: {
    mr: 2,
  },
  gridBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

export interface ReAuthenticateModalProps {
  onClose: () => void;
  onSubmit: () => Promise<void>;
  title: string;
  open?: boolean;
  loading?: boolean;
  error?: string;
}

export const ReAuthenticateModal = ({
  onClose,
  onSubmit,
  open,
  loading,
  error,
  title,
}: ReAuthenticateModalProps) => (
  <Modal open={Boolean(open)} onClose={onClose} title={title}>
    <>
      <Typography>
        To perform this change you must re-authenticate. Re-authentication is
        required to create a digital signature for compliance reasons.
      </Typography>
      {Boolean(error) && <AsyncStatus error={`Error: ${error}`} />}
      <Box marginTop={2}>
        <Grid container item spacing={2}>
          <Grid item xs={12} sx={styles.gridBox}>
            <TrackedButton
              trackLabel="form-cancel-reauthenticate-cancel"
              data-testid="form-cancel-reauthenticate-cancel"
              variant="outlined"
              sx={styles.cancelButton}
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </TrackedButton>
            <TrackedButton
              trackLabel="form-submit-modal-reauthenticate-submit"
              variant="contained"
              type="submit"
              disabled={loading}
              onClick={() => {
                onSubmit();
              }}
            >
              Re-authenticate
            </TrackedButton>
          </Grid>
        </Grid>
      </Box>
    </>
  </Modal>
);
